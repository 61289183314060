@charset "UTF-8";
/*
 * Mixin for placeholder
 * @include placeholderColor(#00ff00);
 */
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url("../font/Montserrat/Montserrat-Bold.eot");
  src: url("../font/Montserrat/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../font/Montserrat/Montserrat-Bold.woff") format("woff"), url("../font/Montserrat/Montserrat-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 400;
  src: url("../font/NotoSans/NotoSansCJKjp-Regular.eot");
  src: url("../font/NotoSans/NotoSansCJKjp-Regular.eot?#iefix") format("embedded-opentype"), url("../font/NotoSans/NotoSansCJKjp-Regular.woff") format("woff"), url("../font/NotoSans/NotoSansCJKjp-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: bold;
  font-weight: 700;
  src: url("../font/NotoSans/NotoSansCJKjp-Bold.eot");
  src: url("../font/NotoSans/NotoSansCJKjp-Bold.eot?#iefix") format("embedded-opentype"), url("../font/NotoSans/NotoSansCJKjp-Bold.woff") format("woff"), url("../font/NotoSans/NotoSansCJKjp-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: bold;
  font-weight: 900;
  src: url("../font/NotoSans/NotoSansCJKjp-Black.eot");
  src: url("../font/NotoSans/NotoSansCJKjp-Black.eot?#iefix") format("embedded-opentype"), url("../font/NotoSans/NotoSansCJKjp-Black.woff") format("woff"), url("../font/NotoSans/NotoSansCJKjp-Black.ttf") format("truetype"); }

@font-face {
  font-family: 'iconfont';
  src: url("/assets/font/iconfont.eot?1546480768320");
  src: url("/assets/font/iconfont.eot?1546480768320#iefix") format("eot"), url("/assets/font/iconfont.woff2?1546480768320") format("woff2"), url("/assets/font/iconfont.woff?1546480768320") format("woff"), url("/assets/font/iconfont.ttf?1546480768320") format("truetype"), url("/assets/font/iconfont.svg?1546480768320#iconfont") format("svg"); }

.l-footerSns_button--twitter:after, .l-footerSns_button--instagram:after, .l-footerSns_button--facebook:after, .l-headerShare_button--twitter:after, .l-headerShare_button--facebook:after {
  font-family: 'iconfont';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  border: 0;
  background: transparent;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: ''; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

[hidden], command, datalist, menu[type=context], rp, source {
  display: none; }

/** html */
/**************************************/
html {
  overflow-y: scroll; }

/** body */
/**************************************/
body {
  line-height: 1;
  color: #000; }

/** Anchor */
/**************************************/
a {
  background: transparent;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
  outline: none; }
  a:hover {
    text-decoration: underline; }

/** Image */
/**************************************/
img {
  border: none;
  vertical-align: bottom; }

/** Paragraph */
/**************************************/
p {
  line-height: 1.8; }

/** Table */
/**************************************/
table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit; }

caption, th {
  text-align: left; }

/** Form */
/**************************************/
input, select, textarea {
  vertical-align: middle; }

/** pre */
/**************************************/
pre {
  border: 1px solid #bfbfbf;
  padding: 5px; }

li {
  list-style-type: none; }

body {
  font-size: 16px;
  font-family: "Noto Sans Japanese" , "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro","メイリオ", Meiryo,  "ＭＳ Ｐゴシック", Osaka, sans-serif;
  line-height: 1.65;
  background: #fff;
  color: #16c61f;
  width: 100%;
  letter-spacing: .25em; }
  @media only screen and (max-width: 799px) {
    body {
      font-size: 14px;
      overscroll-behavior: none none;
      background: #fff;
      overflow: hidden; } }

:placeholder-shown {
  color: #bbb; }

::-webkit-input-placeholder {
  color: #bbb; }

:-moz-placeholder {
  color: #bbb;
  opacity: 1; }

::-moz-placeholder {
  color: #bbb;
  opacity: 1; }

:-ms-input-placeholder {
  color: #bbb; }

@media only screen and (max-width: 799px) {
  .is-scrollLock, .is-scrollLock body {
    overflow: hidden;
    height: 100%; } }

a:visited,
a {
  color: #222;
  cursor: pointer; }

a:hover {
  text-decoration: underline; }

@keyframes fadeInTitle {
  0% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes animation {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% {
    transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% {
    transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% {
    transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% {
    transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% {
    transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% {
    transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% {
    transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% {
    transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% {
    transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% {
    transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } }

.l-page {
  min-width: 1100px;
  margin: auto;
  position: relative;
  z-index: 1;
  overflow: hidden; }
  @media only screen and (max-width: 799px) {
    .l-page {
      min-width: 100%;
      width: 100%;
      overflow: hidden; } }

.l-containers {
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
  min-height: 80vh;
  margin-bottom: 80px; }
  @media only screen and (max-width: 799px) {
    .l-containers {
      width: 100%; } }
  [data-page-id="top"] .l-containers {
    width: 100%; }
  @media only screen and (max-width: 799px) {
    .l-containers {
      padding-top: 50px;
      background: #fff;
      box-sizing: border-box;
      margin-bottom: 0px; } }

.l-content {
  background: #fff;
  padding: 80px;
  margin: 0 0 60px;
  box-sizing: border-box; }
  @media only screen and (max-width: 799px) {
    .l-content {
      margin: 0 0 30px;
      padding: 20px;
      box-shadow: none; } }
  .l-content.is-onlyMobilePadding {
    background: none;
    padding: 0; }
    @media only screen and (max-width: 799px) {
      .l-content.is-onlyMobilePadding {
        padding: 20px; } }
  .l-content--md {
    margin-left: auto;
    margin-right: auto; }
    @media only screen and (max-width: 799px) {
      .l-content--md {
        width: 100%; } }
  .l-content--smMargin {
    margin: 0 0 20px; }
  .l-content--notPadding {
    padding: 0; }
  .l-content a {
    text-decoration: underline; }
    .desctop .l-content a:hover {
      opacity: .7;
      text-decoration: none; }

@media only screen and (max-width: 799px) {
  .l-footer {
    background: #f3f3f3;
    margin-top: 20px;
    width: 100%;
    overflow: hidden; } }

.l-footer_content {
  width: 1100px;
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (max-width: 799px) {
    .l-footer_content {
      width: 100%; } }
  @media only screen and (max-width: 799px) {
    .l-footer_content {
      padding: 20px;
      box-sizing: border-box; } }

.l-footer_logo {
  width: 120px;
  margin: 0 auto;
  padding: 40px 0 20px; }
  .l-footer_logo .svgLogo {
    fill: #fff; }

.l-footer_bottom {
  height: 450px;
  background: #0051e0;
  color: #fff;
  text-align: center; }
  @media only screen and (max-width: 799px) {
    .l-footer_bottom {
      height: auto;
      padding-bottom: 40px; } }
  .l-footer_bottom small {
    font-family: 'Montserrat';
    font-size: 10px;
    letter-spacing: .1em; }

.l-footerSearch {
  margin-bottom: 80px; }
  @media only screen and (max-width: 799px) {
    .l-footerSearch {
      margin-bottom: 40px; } }
  .l-footerSearch_content {
    box-sizing: border-box;
    margin-bottom: 60px; }
    .l-footerSearch_content--area {
      flex-basis: 65%; }
    .l-footerSearch_content--conditions {
      flex-basis: 30%; }
  .l-footerSearch_headline {
    font-size: 20px;
    line-height: 1;
    color: #0051e0;
    margin-bottom: 20px; }
    @media only screen and (max-width: 799px) {
      .l-footerSearch_headline {
        font-size: 16px;
        margin-bottom: 15px; } }
  .l-footerSearch_area {
    display: flex; }
    @media only screen and (max-width: 799px) {
      .l-footerSearch_area {
        display: block;
        margin-bottom: 20px; } }
  .l-footerSearch_areaname {
    flex-basis: 12%; }
  .l-footerSearch_pref {
    flex-basis: 88%; }
  .l-footerSearch_link {
    list-style-type: none;
    display: inline-block;
    margin: 0 10px 5px 0; }
    .l-footerSearch_link--headline {
      font-size: 14px;
      font-weight: bold;
      width: 100px; }
      @media only screen and (max-width: 799px) {
        .l-footerSearch_link--headline {
          display: block;
          margin-top: 20px; } }
    .l-footerSearch_link a {
      font-size: 14px;
      text-decoration: underline; }
      @media only screen and (max-width: 799px) {
        .l-footerSearch_link a {
          font-size: 13px; } }
      .l-footerSearch_link a:hover {
        opacity: .7;
        text-decoration: none; }

.l-footerNav {
  text-align: center;
  padding: 20px 0; }
  @media only screen and (max-width: 799px) {
    .l-footerNav {
      padding-top: 0;
      overflow: hidden; } }
  .l-footerNav_link {
    display: inline-block;
    margin: 0 0 0 20px; }
    @media only screen and (max-width: 799px) {
      .l-footerNav_link {
        display: block;
        width: 50%;
        margin: 0;
        float: left;
        text-align: left; } }
    .l-footerNav_link:first-child:before {
      display: none; }
    .l-footerNav_link:before {
      position: relative;
      margin: 0 20px 0 0;
      top: 3px;
      display: inline-block;
      content: '';
      width: 1px;
      height: 14px;
      background: #fff; }
      @media only screen and (max-width: 799px) {
        .l-footerNav_link:before {
          display: none; } }
    .l-footerNav_link a {
      font-size: 14px;
      color: #fff; }
      @media only screen and (max-width: 799px) {
        .l-footerNav_link a {
          padding: 10px 0;
          display: block;
          border-right: 1px solid #7282fa;
          border-bottom: 1px solid #7282fa;
          padding-left: 20px;
          font-size: 13px; } }

.l-footerSns {
  margin: 40px 0 20px; }
  .l-footerSns_headline {
    font-size: 14px;
    margin-bottom: 20px;
    letter-spacing: .1em; }
  .l-footerSns_content {
    display: flex;
    justify-content: center;
    align-items: center; }
  .l-footerSns_button {
    position: relative;
    width: 40px;
    height: 40px;
    margin: auto 10px;
    border-radius: 50%;
    border: 1px solid #fff; }
    .l-footerSns_button a {
      display: block;
      width: 100%;
      height: 100%; }
    .desctop .l-footerSns_button:hover {
      opacity: .5; }
    .l-footerSns_button:after {
      position: absolute;
      top: 6px;
      left: 12px;
      color: #fff;
      font-size: 18px;
      pointer-events: none; }
    .l-footerSns_button--twitter:after {
      content: ""; }
    .l-footerSns_button--instagram:after {
      content: ""; }
    .l-footerSns_button--instagram:after {
      left: 11px; }
    .l-footerSns_button--facebook:after {
      content: ""; }
    .l-footerSns_button--facebook:after {
      left: 12px; }

.l-header {
  position: relative;
  width: 100%;
  height: 80px;
  z-index: 1000;
  margin-bottom: 20px; }
  @media only screen and (max-width: 799px) {
    .l-header {
      position: fixed;
      top: 0;
      left: 0;
      margin-bottom: 0;
      height: 50px;
      background: #fff;
      overflow: hidden;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.05); } }
  [data-page-id="top"] .l-header {
    position: absolute;
    transform: translateY(-30px);
    opacity: 0; }
    .is-introEnd [data-page-id="top"] .l-header {
      transition-property: opacity transform;
      transition-duration: .6s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-delay: .45s;
      transform: translateY(0px);
      opacity: 1; }
    @media only screen and (max-width: 799px) {
      [data-page-id="top"] .l-header {
        background: none;
        border-bottom: none;
        box-shadow: none; } }
  .is-introSkip .l-header {
    transition-duration: 0s;
    transition-delay: 0;
    transform: translateY(0px);
    opacity: 1; }
  .l-header_content {
    position: relative; }
  .l-header_logo {
    position: absolute;
    top: 20px;
    left: 30px;
    width: 75px; }
    @media only screen and (max-width: 799px) {
      .l-header_logo {
        width: 46px;
        top: 7px;
        left: 20px;
        left: 50%;
        transform: translateX(-50%); } }
    .l-header_logo img {
      width: 100%;
      height: auto; }

.l-headerShare {
  position: absolute;
  top: 15px;
  left: 30px;
  display: flex; }
  @media only screen and (max-width: 799px) {
    .l-headerShare {
      left: 50%;
      transform: translateX(-50%);
      display: none; } }
  .l-headerShare_string {
    font-family: 'Montserrat';
    margin: 8px 10px 0 0;
    font-size: 14px;
    color: #fff; }
    @media only screen and (max-width: 799px) {
      .l-headerShare_string {
        font-size: 12px;
        display: none;
        margin-top: 10px; } }
  .l-headerShare_button {
    margin-right: 6px;
    position: relative;
    top: 6px; }
    .l-headerShare_button a {
      display: block;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #0051e0;
      background: #fff;
      cursor: pointer; }
    .l-headerShare_button:after {
      position: absolute;
      top: 2px;
      left: 6px;
      color: #fff;
      color: #0051e0;
      font-size: 13px;
      pointer-events: none; }
    .l-headerShare_button--twitter:after {
      content: ""; }
    .l-headerShare_button--facebook:after {
      content: ""; }
    .l-headerShare_button--facebook:after {
      left: 6px; }

.uq-shc {
  overflow: hidden; }
  .uq-shc_content {
    width: 600px;
    margin: auto; }
    @media only screen and (max-width: 799px) {
      .uq-shc_content {
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box; } }
  .uq-shc_headline {
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px; }
    @media only screen and (max-width: 799px) {
      .uq-shc_headline {
        font-size: 26px; } }

.uq-shcBg {
  position: fixed;
  background: #000;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url("../img/bg.jpg");
  background-size: cover;
  background-position: center center; }
  .uq-shcBg_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .1;
    transition-property: opacity;
    transition-duration: .35s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .is-scroll .uq-shcBg_overlay {
      opacity: .85; }

.uq-shcHero {
  position: relative;
  width: 100%;
  min-height: 600px;
  height: 100vh; }
  .uq-shcHero img {
    width: 100%;
    height: auto;
    transform: translateZ(0); }
  @media only screen and (max-width: 799px) {
    .uq-shcHero {
      min-height: auto; } }
  .uq-shcHero_logo {
    position: fixed;
    top: 30px;
    left: 30px;
    width: 140px;
    display: block; }
    @media only screen and (max-width: 799px) {
      .uq-shcHero_logo {
        width: 70px;
        top: 20px;
        left: 20px; } }
  .uq-shcHero_jp {
    position: fixed;
    top: 20px;
    right: 40px;
    display: block;
    width: 530px;
    transition-property: all;
    transition-duration: .35s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    @media only screen and (max-width: 799px) {
      .uq-shcHero_jp {
        width: 200px;
        right: 20px;
        top: 30px; } }
    .uq-shcHero_jp img {
      vertical-align: top; }
    .is-scroll .uq-shcHero_jp {
      width: 330px; }
      @media only screen and (max-width: 799px) {
        .is-scroll .uq-shcHero_jp {
          width: 200px; } }
  .uq-shcHero_copy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw + 20px); }
    .uq-shcHero_copy .is-desktop {
      display: block; }
      @media only screen and (max-width: 799px) {
        .uq-shcHero_copy .is-desktop {
          display: none; } }
    .uq-shcHero_copy .is-mobile {
      display: none; }
      @media only screen and (max-width: 799px) {
        .uq-shcHero_copy .is-mobile {
          display: block; } }

.uq-shcAbout {
  padding: 100px 0; }
  .uq-shcAbout img {
    width: 100%;
    height: auto;
    transform: translateZ(0); }
  .uq-shcAbout_copy {
    width: 100%;
    font-size: 50px;
    text-align: justify;
    text-align-last: justify;
    margin-bottom: 1.5em; }
    @media only screen and (max-width: 799px) {
      .uq-shcAbout_copy {
        font-size: 28px; } }
  .uq-shcAbout_body {
    text-align: justify; }
    .uq-shcAbout_body p {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 2em; }
      @media only screen and (max-width: 799px) {
        .uq-shcAbout_body p {
          font-size: 16px; } }
  .uq-shcAbout_parker {
    width: 300px;
    margin: auto;
    font-weight: bold;
    text-align: center; }

.uq-shcRule {
  padding-bottom: 200px; }
  @media only screen and (max-width: 799px) {
    .uq-shcRule {
      padding-bottom: 100px; } }
  .uq-shcRule_lists {
    padding-left: 1.4em; }
    .uq-shcRule_lists li {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
      text-indent: -1em; }
      @media only screen and (max-width: 799px) {
        .uq-shcRule_lists li {
          font-size: 16px; } }
      .uq-shcRule_lists li:before {
        position: relative;
        top: -5px;
        content: '';
        width: 6px;
        height: 6px;
        display: inline-block;
        background: #f30;
        background: #16c61f;
        border-radius: 100%;
        margin-right: 15px; }

.uq-shcFaq {
  padding-bottom: 200px; }
  @media only screen and (max-width: 799px) {
    .uq-shcFaq {
      padding-bottom: 100px; } }
  .uq-shcFaqItem {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px dashed #16c61f; }
    .uq-shcFaqItem dl {
      font-size: 20px; }
      @media only screen and (max-width: 799px) {
        .uq-shcFaqItem dl {
          font-size: 16px; } }
      .uq-shcFaqItem dl span {
        position: absolute;
        left: 0; }
      .uq-shcFaqItem dl dt {
        position: relative;
        font-weight: bold;
        padding-left: 35px;
        margin-bottom: 5px; }
      .uq-shcFaqItem dl dd {
        position: relative;
        padding-left: 35px; }

.uq-shcPhoto {
  border-radius: 20px;
  overflow: hidden;
  width: 300px;
  margin: 30px auto; }
  .uq-shcPhoto img {
    width: 100%;
    height: auto;
    transform: translateZ(0); }
  @media only screen and (max-width: 799px) {
    .uq-shcPhoto {
      width: 200px; } }

.uq-shcLink {
  padding-bottom: 100px; }
  @media only screen and (max-width: 799px) {
    .uq-shcLink {
      padding-bottom: 50px; } }
  .uq-shcLink a {
    display: block;
    width: 300px;
    margin: auto;
    border-bottom: 1px solid #16c61f; }

.uq-shcShare {
  padding-bottom: 200px;
  text-align: center; }
  @media only screen and (max-width: 799px) {
    .uq-shcShare {
      padding-bottom: 100px; } }
